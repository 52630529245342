<template>
  <div
    v-if="userStore.user?.token && $features?.wishlist"
    class="h-[19px] w-[19px] cursor-pointer md:h-[23px] md:w-[23px]"
  >
    <button
      v-if="!loading"
      @click="isWishlisted ? removeFromWishlist() : addToWishlist()"
    >
      <Heart
        class="h-full w-full"
        :fillColor="isWishlisted ? '#C40024' : 'grey'"
      />
    </button>
    <common-loader v-else class="w-[15px] md:w-[23px]" />
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "~/store/common/userStore";
import Heart from "../Icons/Heart.vue";
import { useWishListStore } from "~/store/Features/ProductManagement/wishListStore";

const props = defineProps(["productID"]);
const userStore = useUserStore();
const wishListStore = useWishListStore();

const loading = ref(false);

const isWishlisted = computed(() => wishListStore.inWishlist(props.productID));

const addToWishlist = async () => {
  loading.value = true;
  try {
    await wishListStore.addToWishlist(props.productID);
  } finally {
    loading.value = false;
  }
};

const removeFromWishlist = async () => {
  loading.value = true;
  try {
    await wishListStore.removeFromWishlist(props.productID);
  } finally {
    loading.value = false;
  }
};
</script>
